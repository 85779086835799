@import "../../styles/partials/global";

.game-container {
    height: 35rem;
    width: 52rem;
  
    border-radius: 3rem;
    border: rgb(168, 236, 149) solid 0.75rem;
    border-color: $medium;
    @include border-radius-bottom;
    border-top: none;
    overflow: hidden;
    box-shadow: 0 10px 10px rgba(0,0,0,0.5);
}
