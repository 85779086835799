@import url("https://fonts.googleapis.com/css2?family=Butterfly+Kids&family=Workbench&display=swap");

body {
  font-family: Arial, Helvetica, sans-serif;
  overflow: hidden;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

h1 {
  font-size: 2rem;
  font-weight: 400;
  color: $dark;
  font-family: "Butterfly Kids", cursive;
}

button {
  cursor: pointer;
  position: relative;
  padding: 0.25rem 1rem;
  border-radius: 5rem;
  line-height: 2.5rem;
  font-size: 1rem;
  font-weight: 400;
  color: $dark;

  border: none;
  background-image: linear-gradient(-180deg, #b7e0b2 0%, #eaf6ec 100%);
  box-shadow: 0 -0.25rem 1.5rem rgb(107, 146, 105) inset,
    0 0.75rem 0.5rem rgba(255, 255, 255, 0.4) inset,
    0 0.25rem 0.5rem 0 rgb(171, 228, 153) inset;
}
