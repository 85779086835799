/* ===Breakpoints=== */

$tablet-breakpoint: 768px;
$desktop-breakpoint: 1020px;

/* ===Colours=== */

$primary: rgb(168, 236, 149);
$secondary:  rgb(223, 242, 203);
$medium: #95b493;
$dark: rgb(107, 146, 105);

