@import "../../styles/partials/global";

.help-screen {
  // position: fixed;
  position: absolute;
  height: 35rem;
  width: 21rem;
  top: 0rem;
  right: 0rem;

  border: $medium solid 0.75rem;
  @include border-radius-bottom;
  border-top: none;

  background-color: rgb(178, 218, 167);
  background-image: url(../../assets/images/background-1.jpg);
  background-blend-mode: overlay;
  overflow: hidden;

  z-index: 2;
  padding: 1rem 1.5rem 1.5rem;

  display: flex;
  flex-direction: column;
  line-height: 1.25rem;
  text-align: justify;

  &__image {
    width: 50%;
    margin: 1rem auto;
  }
  &__text {
    &--title {
      text-align: center;
      margin: 0 0 1rem 0;
      color: $dark;
    }
  }
  &__close-button {
    position: fixed;
    height: 2.75rem;
    right: 9.2rem;
    bottom: 3.4rem;
    z-index: 2;
  }
  @media (min-width: 1440px) {
    // margin-right: 2.5vw;
    right: calc(0rem + (100vw - 1440px) / 2);
  }
  @media (max-width: 1440px) {
    margin-right: 0rem;
  }
}

.image-container {
  display: flex;
  flex-direction: column;
}
