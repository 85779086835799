@import "variables";

/* === Media Queries === */

@mixin tablet {
  @media screen and (min-width: $tablet-breakpoint) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $desktop-breakpoint) {
    @content;
  }
}

/* === Styles === */

@mixin container {
  border-radius: 3rem;
  border: $primary solid 0.75rem;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.5);
}

@mixin border-radius-bottom {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 2rem;
  border-bottom-left-radius: 2rem;
}
