@import "../../styles/partials/global";

// Animations 

@keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.2); }
    100% { transform: scale(1); }
  }

  @keyframes tilt {
    0% { transform: rotate(0deg); }
    50% { transform: rotate(5deg); }
    100% { transform: rotate(0deg); }
  }

  @keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: 0; }
  }

// Styles  
.loading-screen-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-screen {
    @include container;
    @include border-radius-bottom;
    border-color: $medium;
    background-image: url(../../assets/images/background-1.jpg);
    background-position: center;

    position: absolute;
    z-index: 4;
    margin: 0 8rem;
    height: 35rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(100% - 16rem);
    top: 6rem;
    max-width: 74rem;;
    
    
    &__text {
        font-size: 3rem;
        animation: pulse 1.5s infinite alternate;
    } 
    &__image {
        width: 18rem;
        margin-top: -3rem;
        animation: tilt 1.5s infinite alternate;
    }

}

.show {
    display: flex;
    animation: fadeOut 1s 2.3s forwards;
}
.hide {
    display: none;
}




