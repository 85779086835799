@import "../../styles/partials/global";
@import "../GamePage/GamePage.scss";

.landing-page {
  background-image: url(../../assets/images/background-1.jpg);
  background-position: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__container {
    @extend .mobile-message;
    padding: 2rem 1rem;
    border-color: $medium;
    margin: 0;
    display: flex;
    width: 60%;
  }
  &__title {
    font-size: 5vw;
    margin-bottom: 1.5rem;
  }
  &__copy {
    margin-bottom: 1.5rem;
  }
  &__enter {
    width: 8rem;
  }
}

.social-container {
  margin-top: 6rem;
  width: 70%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  &__link {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 8rem;
  }

  &__image {
    width: 5rem;
  }
  &__button {
    margin-top: 1rem;
    height: 1.5rem;
    align-items: center;
    font-size: 0.7rem;
    padding: 0;
    line-height: normal;
  }
}
