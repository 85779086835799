@import "../../styles/partials/global";

// Animations
@keyframes slideInFromBottom {
  from {
    bottom: -200rem;
  }
  to {
    bottom: 0rem;
  }
}

@keyframes slideInFromRight {
  from {
    right: -200rem;
  }
  to {
    right: -20;
  }
}

// Styling
.app-container {
  background-image: url(../../assets/images/background-1.jpg);
  width: 100vw;
  height: 100vh;
  padding-top: 1rem;
  @include desktop {
    height: 100vh;
    background-image: none;
    background-color: #a2c983;
    background-image: url(../../assets/images/background-plain.jpg);
    background-position: center;
    background-repeat:repeat-y;
  }
}

.windows-container {
  display: flex;
  margin: 0 8rem;
  padding-bottom: 2rem;
  justify-content: center;
  gap: 1rem;
  position: relative;
}

.app-title {
  padding: 0.5rem 1rem;

  font-size: clamp(16px, 4vw, 60px);
  
  &__container {
    @extend button;
    cursor: pointer;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    display: flex;
    height: 5rem;
    margin: 0 8rem;
    max-width: 74rem;
    justify-content: center;
    align-items: center;
  }
  &__icon {
    width: 5%;
  }
}

.ui-container {
  display: none;
  @include desktop {
    display: inline;
  }
}

.mobile-message {
  background-color: rgb(223, 242, 203);
  width: 60vw;
  @include container;
  margin: 5rem auto;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  &__character {
    max-width: 90%;
    margin: 2rem 0;
  }

  @include desktop {
    display: none;
  }
}

.overlay {
  &__plant-bottom {
    display: none;

    @include desktop {
      display: block;
      position: fixed;
      z-index: 5;
      bottom: 0;
      top: 320px;
      left: 0;
      width: 30rem;
      animation: slideInFromBottom 3.5s forwards;
    }
  }
  &__tree {
    display: none;
    @include desktop {
      display: block;
      position: fixed;
      z-index: 0;
      top: -10rem;
      right: -20rem;
      width: 45rem;
      animation: slideInFromRight 4s forwards;
    }
  }
}



.footer {
  &__container {
    margin-top: 74px;
    width: 100vw;
    height: 100vh;
    background-color:rgb(223, 242, 203);
    border-top: #95b493 solid 0.75rem;
    z-index: 100;
    position: relative;
  }
  &__social-container {
    margin-top: 4rem;
    width: 100vw;
    display: flex;
    justify-content: space-around;
    align-items: center;
    &__link {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 8rem;
    }
  
    &__image {
      width: 5rem;
    }
    &__button {
      margin-top: 1rem;
      height: 1.5rem;
      align-items: center;
      font-size: 0.7rem;
      padding: 0;
      line-height: normal;
    }
  }
}

.title-bar {
  display: flex;
  justify-content: center;
 width: 100vw
}