@import "../../styles/partials/global";

.inventory-container {
  background-image: url(../../assets/images/background-1.jpg);
  @include container;
  border-color: $medium;
  @include border-radius-bottom;
  border-top: none;
  width: 21rem;
  min-width: 21rem;
  padding-bottom: 0.75rem;
  height: 35rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 1;
}

.inventory {
  gap: 1rem;
  padding: 1.5rem;
  height: 30rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  text-align: center;
}

.gift-item {
  overflow: hidden;
  position: relative;
  margin-bottom: 2rem;

  &--normal {
    height: 6rem;
    margin: auto;
  }
  &--special {
    height: 5.5rem;
    padding-top: 1.5rem;
  }
}

.gift-item img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.button-container {
  display: flex;
  justify-content: space-between;
  align-items: end;
  padding: 0 0.8rem;
}

.help-screen__button {
  height: 4rem;
  width: 4rem;
  font-size: 2rem;

  z-index: 2;
  &--close {
    z-index: 3;
  }
}
